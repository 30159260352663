import { PageSectionTypes } from '../types';

import { PageSectionComponent } from '../types';
import { IconLinksSectionProps } from './types';

export const toIconSizeRem = (totalItems: number) => {
  const MAX_ICON_SIZE_REM = 4.8;
  const MIN_ICON_SIZE_REM = 3.2;

  return Math.max(
    Math.min(28 / totalItems, MAX_ICON_SIZE_REM),
    MIN_ICON_SIZE_REM
  );
};

export const shouldGroup: PageSectionComponent<IconLinksSectionProps>['shouldGroup'] =
  ({ props, prevSection }) => {
    const pageTitleIsBefore = prevSection.type === PageSectionTypes.PAGE_TITLE;
    const hasOwnTitle = !!props.title;

    return pageTitleIsBefore && !hasOwnTitle;
  };

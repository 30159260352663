import { PageSectionComponent } from '~/app/components/ItemPage/sections/types';
import { PageTitleText } from './components';
import { PageTitleSectionProps } from './types';
import ArtistNames from '../../ArtistNames';

const PageTitleSection: PageSectionComponent<PageTitleSectionProps> = ({
  layoutData,
}) => {
  const { item } = layoutData;
  const text = item.name;
  let subtitle: React.ReactNode | null = null;

  if (!text) {
    return null;
  }

  if ('artistIds' in item) {
    subtitle = <ArtistNames artistIds={item.artistIds} />;
  }

  return (
    <div data-testid="pageTitleSection">
      <PageTitleText text={text} subtitle={subtitle} />
    </div>
  );
};

export default PageTitleSection;

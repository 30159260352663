import { CSSProperties, FC, ReactNode, useMemo } from 'react';

import {
  PageSectionDefinition,
  PageSectionPreset,
  ItemContext,
  ResolvedPageSection,
} from '../types';

import { PageSectionComponent, PageSectionTypes } from '../sections/types';
import isTruthy from '~/app/lib/utils/isTruthy';

// used by AddFeatureDialog to find page section elements
export const PAGE_SECTION_CLASS = 'pageSection';

export interface SectionRendererProps {
  itemContext: ItemContext;
  pageSection: PageSectionDefinition<any>;
  sectionPath: string;
  sectionIndex: number;
  components: Record<string, PageSectionComponent>;
  style?: CSSProperties;
}

export const PageSections: FC<{
  items: PageSectionDefinition[];
  itemContext: ItemContext;
  components: Record<string, PageSectionComponent>;
  id: string;
  groupSections?: PageSectionTypes[][];
  withTopMargin?: boolean;
  renderItem?: (params: {
    component: ReactNode;
    index: number;
    isGrouped: boolean;
  }) => ReactNode;
}> = ({ items, components, id, itemContext, renderItem, withTopMargin }) => {
  const sectionEls = useMemo(() => {
    const result = items
      .map((item, index) => {
        const resolved = resolvePageSection({
          itemContext,
          pageSection: item,
        });

        const Component = resolved?.component && components[resolved.component];
        const type = resolved?.component;

        if (!type || !Component) {
          return null;
        }

        const props = {
          key: `${item.component || item.preset}${index}`,

          // default section id to ensure the ItemNav works
          sectionId: `section${index}`,

          ...resolved!.props,
          // ...restProps,

          sectionPath: `${id}[${index}]`,
          sectionIndex: index,
        };

        return {
          type,
          Component,
          props,
        };
      })
      .filter(isTruthy);

    return result.map((item, index, items) => {
      const prevItem = items[index - 1];
      const prevType = prevItem?.type;
      const { Component, props } = item;
      const isFirstSection = index === 0;

      const isGrouped = !!(
        prevType &&
        item.Component.shouldGroup?.({
          props,
          prevSection: { type: prevType },
        })
      );

      const component = (
        <section
          key={`${item.type}${index}`}
          // this allowed the <ItemPageNav> component to scroll to sections
          id={props.sectionId}
          className={PAGE_SECTION_CLASS}
          data-testid="pageSection"
          style={{
            // show section top 20% down the viewport when scrolled to via <ItemPageNav>
            scrollMarginTop: '20vh',

            marginTop:
              (!isFirstSection &&
                withTopMargin &&
                (isGrouped ? '2rem' : '7rem')) ||
              0,
          }}
        >
          <Component {...props} key={props.key} isGrouped={isGrouped} />
        </section>
      );

      if (renderItem) {
        return renderItem({
          component,
          index,
          isGrouped,
        });
      }

      return component;
    });
  }, [items, components, id, itemContext, renderItem]);

  return <>{sectionEls}</>;
};

export const resolvePageSection = ({
  pageSection,
  itemContext,
}: Pick<SectionRendererProps, 'pageSection' | 'itemContext'>):
  | ResolvedPageSection
  | undefined => {
  const { preset: presetId, shared, ...restPageSection } = pageSection;

  // when the layout item is references a shared component
  if (shared) {
    // find the shared item in the ItemContext that matches the id
    const sharedItem = itemContext.shared?.[shared];

    // Oops couldn't find a matching shared item in the layout context,
    // this can happen if the shared component is deleted at source.
    // We can remove this broken reference on next Edit Page save.
    if (!sharedItem) return;

    // resolve the layout item using the localContext and the share item
    return sharedItem;
  }

  const preset =
    presetId && (itemContext.presets[presetId] as PageSectionPreset);

  // a merge of the pageSection and the defined preset
  return {
    ...restPageSection,
    ...preset,

    props: {
      ...preset?.props,
      ...restPageSection?.props,
      layoutData: itemContext.data,
    },
  };
};

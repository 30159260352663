import { ComponentType, FC } from 'react';

import { ShareIds } from '~/app/pages/ItemPageArtist/useSharedArtistComponents/constants';
import { ToStructuredDataExtra } from '../PageMetadata/toStructuredData';
import { AddonsConfig, OrchardBrands } from '~/app/lib/songwhipApi/types';
import { SelectedItem, SelectedCustomPage } from '~/app/lib/store/types';
import { PageProps } from '../Page';

import {
  PageSectionTypes,
  ItemLinksCustomLink,
  PageSectionBaseProps,
} from './sections/types';

import { ItemPageHeaderProps } from './ItemPageHeader';

export enum LayoutSlotIds {
  BACKGROUND = 'background',
  HERO = 'hero',
  MAIN = 'main',
}

export enum PresetTypes {
  STREAM_LINKS = 'STREAM_LINKS',
  BUY_LINKS = 'BUY_LINKS',
  SOCIAL_LINKS = 'SOCIAL_LINKS',
  DESCRIPTION = 'DESCRIPTION',
  PRESAVE_BUTTONS = 'PRESAVE_BUTTONS',
  SUBSCRIBE_TO_RELEASE_BUTTONS = 'SUBSCRIBE_TO_RELEASE_BUTTONS',
  RELEASES = 'RELEASES',
  VIDEOS = 'VIDEOS',
  SHOWS = 'SHOWS',
  MERCH = 'MERCH',
  CAROUSEL = 'CAROUSEL',
  ARTIST_ICONS = 'ARTIST_TITLE_ICONS',
}

export interface PageSectionPreset<TProps = any>
  extends Omit<ResolvedPageSection, 'props'> {
  props: TProps &
    Pick<PageSectionBaseProps, 'navTitle'> &
    Partial<Pick<PageSectionBaseProps, 'sectionId'>>;
}

/**
 * A final definition of a component positioned in
 * one of the available layout slots.
 */
export interface PageSectionDefinition<Props = any>
  extends Omit<Partial<ResolvedPageSection>, 'props'> {
  /**
   * A reference to a preset in the parent itemContext.
   * Before render the PageSection object is merged into the
   * PageSectionPreset, this means that we only need to define/store
   * a subset of PageSection props.
   */
  preset?: PresetTypes;

  /**
   * A reference to a shared component that should be rendered in place
   */
  shared?: string;

  props?: Props;
}

export interface ResolvedPageSection {
  /**
   * The actual component type to render
   */
  component?: PageSectionTypes;

  /**
   * Flag the component as shareable and id is used store
   * and reference the component from other layouts.
   */
  shareId?: string;

  /**
   * The props to pass to the component
   */
  props: Omit<PageSectionBaseProps, 'sectionId'> & {
    [key: string]: any;

    // sectionId is not required, a fallback id will be provided when undefined
    sectionId?: string;
  };

  /**
   * A human readable name for the preset used in Edit Mode
   */
  displayName?: string;
}

export interface ItemPageLayoutDefinition {
  /**
   * @deprecated
   */
  background?: PageSectionDefinition<{ url: string }>;

  hero?: PageSectionDefinition[];
  main: PageSectionDefinition[];
}

export interface LayoutData {
  /**
   * The underlying item object
   */
  item: SelectedItem | SelectedCustomPage;

  /**
   * A hash of user defined custom objects.
   */
  custom?: {
    [objectId: string]: ItemLinksCustomLink;
  };
}

export interface ItemContext {
  /**
   * A collection of 'preset' PageSections by id. They act as a 'base'
   * PageSection that users can add/override config to. These can be used
   * in the `layout` and avoids duplicating config keeping untouched
   * components in a custom layout on the 'update channel'.
   */
  presets: {
    [presetType: string]: PageSectionPreset;
  };

  /**
   * The actual layout definition comprising of 'slots' w/ one or many
   * PageSections that get resolved and inflated at runtime by PageSectionRenderer
   */
  layout: ItemPageLayoutDefinition;

  /**
   * A data store comprising of 'default' data from originating from
   * songwhip-lookup and 'custom' data that users have defined using
   * the Edit UI.
   */
  data: LayoutData;

  addons: AddonsConfig;

  shared?: {
    [ShareIds.SOCIAL_LINKS]?: ResolvedPageSection;
  };

  /**
   * When editing we have two ItemContexts one for the clone that we're editing
   * and one for the original, this allows us to compare the original w/ the edit state,
   * which is required during save to workout what changed.
   */
  originalItemContext?: ItemContext;
}

// the external interface
export interface ItemPageProps
  extends Pick<
    PageProps,
    | 'pageType'
    | 'trackerContext'
    | 'renderFooter'
    | 'onScroll'
    // | 'withOverflowGradients'
    | 'withBeforeContentAfterMain'
  > {
  /**
   * Indicate whether there is enough content to render.
   *
   * If `false` and `isLoading: false` a loading spinner will
   * be shown. If `true` and `isLoading: true` then we can show
   * content and load in the background (eg. in refresh/localize case).
   *
   * This is also used internally infer decide whether to trigger
   * the 'page-view' tracking event. We don't want to trigger the
   * `page-view` if we haven't got data that needs to be sent with
   * the event.
   */
  hasContent: boolean;
  isLoading: boolean;
  isOwned: boolean | undefined;
  isDraft?: boolean;
  isOrchardPage?: boolean | undefined;
  pageBrand?: OrchardBrands;
  pageOwnedByAccountIds: number[] | undefined;
  error?: Error;
  pagePath: string;
  artistPagePath?: string;
  headerToolbarHeight?: string;
  heroHeight: string | number;
  backgroundHeight: string | number;
  itemContext: ItemContext;
  toStructuredData?: ToStructuredDataExtra;
  shareText?: string;
  facebookPixelId?: string;
  renderFooterContent?: () => JSX.Element | undefined;
  renderBeforeHeader?: () => JSX.Element | undefined | null;
  renderHeaderContent?: () => JSX.Element | undefined | null;
  renderContent?: () => JSX.Element | undefined;
  userCanEdit: boolean;
  displayType: 'artist' | 'album' | 'song' | 'custom';
  toActionItems: ItemPageHeaderProps['toActionItems'];
  testId?: string;
  withRedirectIfNeeded?: boolean;

  /**
   * Additional layout components can be passed in. This is useful
   * for ItemPage types that use components others' don't. Avoids
   * bloating this shared module.
   */
  pageSectionComponents?: {
    [sectionType: string]: FC | ComponentType;
  };
}

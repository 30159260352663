import BlockText from './sections/BlockText';
import ItemLinks from './sections/ItemLinks';
import { PageSectionTypes } from './sections/types';
import PageTitleSection from './sections/PageTitleSection';
import IconLinksSection from './sections/IconLinksSection';

export const MAX_CONTENT_WIDTH = '48rem';
export const IMAGE_WIDTH_LARGE = 1200;
export const IMAGE_WIDTH_SMALL = 750;
export const IMAGE_QUALITY = 60;
export const PAGE_CONFIG_VERSION = 2;

export const CORE_SECTION_COMPONENTS = {
  [PageSectionTypes.ITEM_LINKS]: ItemLinks,
  [PageSectionTypes.PAGE_TITLE]: PageTitleSection,
  [PageSectionTypes.BLOCK_TEXT]: BlockText,
  [PageSectionTypes.ICON_LINKS]: IconLinksSection,
};

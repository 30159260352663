import React, { useCallback } from 'react';
import classNames from 'classnames';

import useTheme from '~/app/lib/hooks/useTheme';

import { PageSectionComponent } from '../types';
import { useRegisterNavItem } from '../../ItemPageNav';
import IconLinkButton from '../lib/IconLinkButton';
import SectionTitle from '../lib/SectionTitle';
import { resolveLinks } from '../lib';
import { IconLinksSectionProps } from './types';
import { shouldGroup, toIconSizeRem } from './utils';
import HorizontalScroller from '~/app/components/Scroller2/HorizontalScroller';

const IconLinksSection: PageSectionComponent<IconLinksSectionProps> = ({
  title,
  links,
  layoutData,
  sectionId,
  navTitle,
  sectionIndex,
  isGrouped,
  isSocial,
}) => {
  const theme = useTheme();

  const linksResolved = resolveLinks({ layoutData, links });
  // unable to resolve any links from given props
  if (!linksResolved) return null;

  useRegisterNavItem({
    id: sectionId,
    text: navTitle,
    index: sectionIndex,
  });

  const iconSizeRem = Math.min(
    toIconSizeRem(linksResolved.length),
    isGrouped ? 4 : 4.8
  );

  return (
    <div
      data-testid={classNames('iconLinksSection', {
        socialIconLinksSection: isSocial,
      })}
      className="icons"
      style={{ color: theme.textColor90 }}
    >
      <SectionTitle margin="0 0 2.3rem" text={title} />
      <HorizontalScroller
        centerContent
        margin="1.25rem 0 -0.5rem"
        gradientColor="mask"
        overflowStyle="outside"
        arrowSize="1.6rem"
        contentStyle={{
          padding: '0 .5rem',
        }}
        renderContent={useCallback(() => {
          return linksResolved.map((resolved) => {
            return (
              <IconLinkButton
                testId={resolved.service.type}
                key={resolved.link}
                Icon={resolved.Icon || resolved.service.Icon}
                link={resolved.link}
                name={resolved.text}
                size={`${iconSizeRem}rem`}
                margin="0 .15em"
              />
            );
          });
        }, [linksResolved])}
      />
      <style jsx>{`
        .icons :global(.Icon) {
          transition: opacity 0.6s;
        }

        .icons:hover :global(.Icon) {
          opacity: 0.8;
        }

        .icons :global(.Icon:hover) {
          opacity: 1;
          transform: scale(1.02);
          color: currentColor !important;
        }
      `}</style>
    </div>
  );
};

IconLinksSection.shouldGroup = shouldGroup;

export default IconLinksSection;

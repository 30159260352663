import React, { memo, RefObject } from 'react';
import Debug from 'debug';

import LazyHydrateDelay from '../LazyHydrate/LazyHydrateDelay';
import PageLoading from '~/app/components/PageLoading';
import PageError from '~/app/components/PageError';
import { PAGE_HEADER_HEIGHT } from '~/app/components/PageHeader';
import Sticky from '~/app/components/Sticky';
import Box from '~/app/components/Box';
import { useI18n } from '~/app/lib/i18n';

const debug = Debug('songwhip/ItemPageContent');

const ItemPageContent = memo<{
  renderHeroContent?: () => JSX.Element | JSX.Element[] | null | void;
  renderMainContent: () => JSX.Element | null | void;
  headerToolbarHeight?: string;
  heroHeight: string | number;
  titleRef: RefObject<any>;
  error?: Error;
  isLoading: boolean;
}>(
  ({
    error,
    isLoading,
    renderHeroContent,
    renderMainContent,
    headerToolbarHeight = '0px',
    heroHeight,
    titleRef,
  }) => {
    debug('render');

    const { t } = useI18n('app');

    // only show loading spinner if the don't have any content to show
    if (isLoading) {
      return <PageLoading />;
    }

    // only show the error if we don't have any useful content to show
    if (error) {
      return (
        <PageError
          error={error}
          toText={({ status }) => {
            if (status === 404) {
              return t('errors.item');
            }
          }}
        />
      );
    }

    return (
      <>
        <Sticky top={0} zIndex={0}>
          <Box
            nodeRef={titleRef}
            style={{
              // reserve space for toolbar and header
              // REVIEW: Better solution would be to have sticky header instead
              // of absolute, in that case we can avoid reserving space
              paddingTop: `calc(${PAGE_HEADER_HEIGHT} + ${headerToolbarHeight})`,

              willChange: 'opacity',
              // fallback for browsers that don't have css min() max()
              fontSize: 40,
              // ie11 layout fix
              width: '100%',
            }}
            minHeight={heroHeight}
            isCentered
            padding="0 0 2.5rem"
            centerContent
            flexColumn
          >
            {renderHeroContent?.() ?? null}
          </Box>
        </Sticky>

        {/* TODO: Use DynamicLoadingObserver to show spinner when dynamic sections not loaded */}
        <LazyHydrateDelay
          delay={500}
          style={{
            // grow to fill available space so that when section content is shorter than
            // viewport we don't see where the gradient (defined below) ends
            flexGrow: 1,
            flexShrink: 0,
            position: 'relative',
          }}
        >
          {renderMainContent() ?? null}
        </LazyHydrateDelay>
      </>
    );
  }
);

export default ItemPageContent;

import { PageSectionPreset } from '../../types';
import { PageSectionTypes } from '../types';
import { IconLinksSectionProps } from './types';

export const toSocialLinksSectionPreset = ({
  shareId,
  ...props
}: {
  title: string;
  navTitle: string;
  shareId?: string;
}): PageSectionPreset<IconLinksSectionProps> => ({
  component: PageSectionTypes.ICON_LINKS,
  shareId,

  props: {
    ...props,
    sectionId: 'follow',

    isSocial: true,

    links: [
      '$.item.links.twitter',
      '$.item.links.facebook',
      '$.item.links.instagram',
      '$.item.links.youtube',
      '$.item.links.tiktok',
    ],
  },
});

export const toArtistIconsPreset =
  (): PageSectionPreset<IconLinksSectionProps> => ({
    component: PageSectionTypes.ICON_LINKS,

    props: {
      links: [
        '$.item.links.spotify',
        '$.item.links.itunes',
        '$.item.links.youtube',
        '$.item.links.instagram',
        '$.item.links.twitter',
        '$.item.links.facebook',
        '$.item.links.deezer',
        '$.item.links.amazonMusic',
        '$.item.links.tiktok',
        '$.item.links.tidal',
        '$.item.links.snapchat',
        '$.item.links.soundcloud',
      ],
    },
  });
